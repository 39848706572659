<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
})

definePageMeta({
  // empty layout was causing trouble with Muxt, so only re-introduce that if the nuxt bug is fixed.
  // layout: 'empty',
  isPublic: true,
})

usePageTitle(computed(() => t('pageTitle')))
</script>


<!-- <div class="dark:bg-muted-800 flex min-h-screen bg-white">
    <div
      class="bg-muted-100 dark:bg-muted-900 relative hidden w-0 flex-1 items-center justify-center lg:flex lg:w-3/5"
    >
      <div
        class="mx-auto flex h-full w-full max-w-4xl items-center justify-center"
      >
        <NuxtLink to="/">
          <PublicLogo class="mx-auto max-w-xl" width="619" height="594" />
        </NuxtLink>
      </div>
    </div>
    <div
      class="relative flex flex-1 flex-col justify-center px-6 py-12 lg:w-2/5 lg:flex-none"
    >
      <AuthLogin />
    </div>
  </div> -->

<template>
  <CardMedium class="mb-10">
    <AuthLogin class="rounded-xl pb-4" />
  </CardMedium>
</template>

<i18n lang="json">
{
  "en": {
    "pageTitle": "Login"
  },
  "nl": {
    "pageTitle": "Inloggen"
  }
}
</i18n>
