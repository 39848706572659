<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import { Field, useForm } from 'vee-validate'
import { object, string, type InferType } from 'yup'

const props = withDefaults(defineProps<{ title?: string, subTitle?: string, usernameField?: string, usernameLabel?: string, validateEmail?: boolean, showSignupLink?: boolean }>(), {
  // The title for the login form
  title: undefined,
  // The subtitle for the login form
  subTitle: undefined,
  // The field name to use for the username/email input (and posting to the API)
  usernameField: 'email',
  // The label for the username/email input
  usernameLabel: undefined,
  // Don't show the signup links for meedoen
  showSignupLink: undefined,
})

// Don't use useRuntimeConfig() in withDefaults, or it will crash
const showSignupLink = props.showSignupLink ?? !useRuntimeConfig().public.isMeedoen

const { t } = useI18n({
  useScope: 'local',
})

// This is the Yup schema for the form input
// It's used to define the shape that the form data will have
const loginSchema = object({
  [props.usernameField]: props.usernameField == 'email'
    ? string().email().required()
    : string().required().label(props.usernameLabel ?? t('username')),
  password: string().required().min(1),
  securityCode: string().nullable().min(1),
})

const initialValues = useInitialAuthValues(props.usernameField)

const { handleSubmit, isSubmitting, setFieldError, values } = useForm({
  validationSchema: toTypedSchema(loginSchema),
  initialValues,
})

const router = useRouter()
const toaster = useToaster()

const { handleServerError } = useServerErrorHandler(loginSchema, setFieldError)
const { initialize } = useCurrentOrganizationStore()

const showSecurityCodeField = ref(false)

const onSubmit = handleSubmit(async (values) => {
  try {
    await loginUser(values)

    // console.log(`[Login] wait...`)
    // Wait for auth token to be set through cookies, to avoid race conditions.
    // Ideally, we wouldn't have to do this. A refactor of the auth state would solve that.
    await new Promise(resolve => setTimeout(resolve, 1000))
    // console.log(`[Login] done...`)
    // The load the user's organizations
    await initialize()

    toaster.clearAll()
    toaster.show({
      title: 'Ingelogd',
      message: `Welkom terug!`,
      color: 'success',
      icon: 'ph:user-circle-fill',
      closable: true,
    })

    router.push('/dashboard')
  }
  catch (error: any) {
    const errorMessage = error.data.errors[0]
    const success = error.data.success

    if (errorMessage.includes('security code')) {
      showSecurityCodeField.value = true
      return
    }

    if (success === false && errorMessage) {
      // It was a regular "auth failed" error
      setFieldError('password', errorMessage)
      // Also give the email input a red border (but no error msg)
      setFieldError('email', ' ')
    }
    else {
      // Some other server error occured
      handleServerError(error)
    }
  }
})

</script>

<template>
  <div class="dark:bg-muted-800 relative mx-auto w-full max-w-sm bg-white">
    
    <div>
      <BaseHeading
        as="h2"
        size="3xl"
        lead="relaxed"
        weight="normal"
        class="mt-6"
      >
        {{ title || t('title') }}
      </BaseHeading>
      <BaseParagraph size="sm" class="text-muted-400 mb-6">
        {{ subTitle || t('subtitle') }}
      </BaseParagraph>
    </div>

    
    <div class="mt-6">
      <div class="mt-5">
        
        <form
          method="POST"
          action=""
          class="mt-6"
          novalidate
          @submit.prevent="onSubmit"
        >
          <div class="space-y-4">
            <Field
              v-slot="{ field, errorMessage }"
              :name="usernameField"
            >
              <BaseInput
                v-bind="field"
                :error="errorMessage"
                :disabled="isSubmitting"
                :type="usernameField == 'email' ? 'email' : undefined"
                :label="usernameLabel || t('email')"
                :placeholder="usernameField == 'email' ? t('email') : usernameLabel"
                shape="curved"
                :classes="{
                  input: 'h-12',
                }"
              />
            </Field>

            <Field
              v-slot="{ field, errorMessage }"
              name="password"
            >
              <BaseInput
                v-bind="field"
                :error="errorMessage"
                :disabled="isSubmitting"
                type="password"
                :label="`${t('password')}`"
                :placeholder="`${t('password')}`"
                shape="curved"
                :classes="{
                  input: 'h-12',
                }"
              />
            </Field>

            <BaseMessage v-if="showSecurityCodeField" info>
              {{ t('securityCodeInfo') }}
            </BaseMessage>

            <Field
              v-if="showSecurityCodeField"
              v-slot="{ field, errorMessage }"
              name="securityCode"
            >
              <BaseInput
                v-bind="field"
                :error="errorMessage"
                :disabled="isSubmitting"
                :label="t('securityCode')"
                :placeholder="t('securityCode')"
                shape="curved"
                :classes="{
                  input: 'h-12',
                }"
              />
            </Field>
          </div>

          <div class="mt-6 flex items-center justify-between">
            <div class="text-xs leading-5">
              <NuxtLink
                to="/auth/recover"
                class="text-primary-600 hover:text-primary-500 font-sans font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline"
              >
                {{ t('forgotPassword') }}
              </NuxtLink>
            </div>
          </div>

          
          <div class="mt-6">
            <div class="block w-full rounded-md shadow-sm">
              <BaseButton
                :disabled="isSubmitting"
                :loading="isSubmitting"
                type="submit"
                color="primary"
                class="!h-11 w-full"
              >
                {{ t('signIn') }}
              </BaseButton>
            </div>
          </div>
        </form>

        
        <p
          v-if="showSignupLink"
          class="text-muted-400 mt-4 flex justify-between font-sans text-xs leading-5"
        >
          <span>{{ t('noAccount') }}</span>
          <NuxtLink
            to="/signup"
            class="text-primary-600 hover:text-primary-500 font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline"
          >
            {{ t('createAccount') }}
          </NuxtLink>
        </p>
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Welcome back.",
    "subtitle": "Login with your email and password",
    "email": "E-mail address",
    "username": "Username",
    "password": "Password",
    "forgotPassword": "Forgot your password?",
    "securityCode": "Veiligheidscode",
    "securityCodeInfo": "Enter the security code you received by email.",
    "signIn": "Sign in",
    "noAccount": "Don't have an account?",
    "createAccount": "Create an account"
  },
  "nl": {
    "title": "Welkom terug.",
    "subtitle": "Log in met je e-mailadres en wachtwoord",
    "email": "E-mailadres",
    "username": "Gebruikersnaam",
    "password": "Wachtwoord",
    "securityCode": "Veiligheidscode",
    "securityCodeInfo": "Vul de veiligheidscode in die je per e-mail hebt ontvangen.",
    "forgotPassword": "Je wachtwoord vergeten?",
    "signIn": "Inloggen",
    "noAccount": "Heb je geen account?",
    "createAccount": "Account aanmaken"
  }
}
</i18n>
